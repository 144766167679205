<!--
Example usage:

<craft-contact-form success="Správa bola úspešne odoslaná">
    <input type="text" name="fromName" placeholder="Meno">
    <input type="text" name="fromEmail" placeholder="Email">
    <input type="text" name="message[phone]" placeholder="Telefón">
    <textarea name="message[body]" placeholder="Podrobnejšie napíšte, o čo máte záujem"></textarea>
    <button type="submit" class="button small">Odoslať</button>
</craft-contact-form>
-->

<template>
    <form method="post" accept-charset="UTF-8" @submit.prevent="send">
        <input type="hidden" name="action" value="contact-form/send">
<!--        <div class="errors"><div class="error" v-for="error in errors">{{ error }}</div></div>-->
        <slot></slot>
    </form>
</template>

<script>
    import $ from 'jquery';
    import eventBus from '../EventBus';

    export default {

        props: ['success'],

        data: function() {
            return {
                errors: []
            }
        },

        methods: {
            send() {
                var data = $(this.$el).serialize();
                data += '&' + window.App.csrfTokenName + '=' + window.App.csrfTokenValue;

                this.errors = [];

                $.post('/', data, (response) => {
                    console.log(response);
                    if (response.success) {
                        eventBus.$emit('notification', this.success, 'success');
                        this.resetForm();
                        this.errors = [];
                    } else {
                        for (var property in response.errors) {
                            if (response.errors.hasOwnProperty(property)) {
                                this.errors.push(response.errors[property][0]);
                            }
                        }
                        eventBus.$emit('notification', this.errors.join('<br>'), 'error')
                    }
                }, 'json');
            },

            resetForm() {
                $(this.$el)[0].reset();
            }
        }

    };
</script>