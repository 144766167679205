<template>
    <button class="hamburger" :class="['hamburger--'+type, {'is-active': activated}]" type="button">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
</template>

<script>
    import $ from 'jquery';

    export default {
        props: ['type', 'activated'],

        methods: {
            activate() {
                $(this.$el).toggleClass('is-active');
            }
        }
    }
</script>

<style>
    /*!
     * Hamburgers
     * @description Tasty CSS-animated hamburgers
     * @author Jonathan Suh @jonsuh
     * @site https://jonsuh.com/hamburgers
     * @link https://github.com/jonsuh/hamburgers
    */
    .hamburger {
        padding: 15px 15px;
        display: inline-block;
        cursor: pointer;
        transition-property: opacity, -webkit-filter;
        transition-property: opacity, filter;
        transition-property: opacity, filter, -webkit-filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        outline: none;
        overflow: visible; }

    @media(hover: hover) {
        .hamburger:hover {
            opacity: 0.7;
        }
    }

    .hamburger-box {
        width: 40px;
        height: 24px;
        display: inline-block;
        position: relative; }

    .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: -2px; }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        width: 40px;
        height: 4px;
        background-color: white;
        border-radius: 4px;
        position: absolute;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        transition-duration: 0.15s;
        transition-timing-function: ease; }
    .hamburger-inner::before, .hamburger-inner::after {
        content: "";
        display: block; }
    .hamburger-inner::before {
        top: -10px; }
    .hamburger-inner::after {
        bottom: -10px; }


    /*
     * Spring
     */
    .hamburger--spring .hamburger-inner {
        top: 2px;
        transition: background-color 0s 0.15s linear; }
    .hamburger--spring .hamburger-inner::before {
        top: 10px;
        transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--spring .hamburger-inner::after {
        top: 20px;
        transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

    .hamburger--spring.is-active .hamburger-inner {
        transition-delay: 0.32s;
        background-color: transparent; }
    .hamburger--spring.is-active .hamburger-inner::before {
        top: 0;
        transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
        transform: translate3d(0, 10px, 0) rotate(45deg); }
    .hamburger--spring.is-active .hamburger-inner::after {
        top: 0;
        transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
        transform: translate3d(0, 10px, 0) rotate(-45deg); }




</style>