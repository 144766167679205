<!-- example of use
<basic-header :height="120" single-page sticky>
    <span slot="logo">COMPANY</span>
    <nav slot="nav">
        <a href="#section1">Link 1</a>
        <a href="#section2">Link 2</a>
        <a href="#section3">Link 3</a>
        <a href="#section4">Link 4</a>
    </nav>
</basic-header>
-->

<template>
    <div class="header-wrap" :class="{'sticky': sticky}">
        <header class="basic" ref="main">
            <div class="wrap">
                <a href="/" class="logo">
                    <slot name="logo"></slot>
                </a>
                <div class="nav-normal">
                    <slot name="nav"></slot>
                </div>
                <hamburger type="spring" @click.native="toggleMobileNav" :activated="mobileNavOpened"></hamburger>
            </div>
        </header>
        <div class="nav-mobile" ref="mobileNav">

        </div>
    </div>
</template>

<style scoped>
    .header-wrap {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
    }

    header.basic .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
    }

    .hamburger {
        display: none;
        z-index: 20;
    }

    .nav-mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px;
        color: white;

        transform: translateX(100vw);
        transition: transform 0.7s;
    }

    .nav-mobile.opened {
        transform: translateX(0);
    }

    .nav-mobile nav {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @media(max-width: 920px) {
        .hamburger {
            display: block;
        }

        .nav-normal {
            display: none;
        }
    }
</style>

<script>
    import $ from 'jquery';
    import scrollToElement from 'scroll-to-element';
    import Hamburger from './Hamburger.vue';

    export default {
        components: {
            Hamburger
        },

        props: {
            sticky: {
                type: Boolean
            },

            singlePage: {
                type: Boolean
            },

            height: {
                type: Number,
                default: 100
            }
        },

        data() {
            return {
                mobileNavOpened: false
            }
        },

        mounted() {
            this.$nextTick(() => {
                this.setInitialHeight();

                this.copyNavToMobile();

                this.setNavLinksScrolling();
            });
        },

        methods: {
            copyNavToMobile() {
                let nav = $('.nav-normal').html();
                $(this.$refs.mobileNav).append(nav);
            },

            setInitialHeight() {
                $(this.$refs.main).css('height', this.height);
//                $(this.$refs.mobileNav).css({
//                    top: this.height,
//                    height: 'calc(100vh - '+this.height+'px)'
//                });

                if(this.sticky) {
                    $('body').css('paddingTop', this.height);
                }
            },

            setNavLinksScrolling() {
                let vm = this;
                if(this.singlePage) {
                    $(this.$el).on('click', 'nav a', function(e) {
                        e.preventDefault();
                        scrollToElement(e.target.hash, {
                            duration: 1500,
                            ease: 'inOutCube',
                            offset: -1 * vm.height
                        });
                        if(vm.mobileNavOpened) {
                            vm.toggleMobileNav();
                        }
                    });
                }
            },

            toggleMobileNav() {
                $(this.$refs.mobileNav).toggleClass('opened');
                this.mobileNavOpened = !this.mobileNavOpened;
            }
        }
    }
</script>