<template>
    <a href="#" class="gallery-link">Fotogaléria</a>
</template>

<script>
    import $ from 'jquery';
    import MagnificPopup from 'magnific-popup';

    export default {
        props: {
            images: {
                type: Array,
                required: true
            }
        },

        mounted() {
            this.$nextTick(() => {
                $(this.$el).magnificPopup({
                    items: this.images,
                    gallery: {
                        enabled: true
                    },
                    type: 'image' // this is default type
                });
            });
        },
    }
</script>